* {
  user-select: none;
}

.about__container {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  // column-gap: 0rem;
}

.about_name_newari {
  margin-top: 5px;
  width: 120px;
  opacity: 0.5;
  margin-bottom: -60px;
}

.about__description {
  // text-align: justify;
  padding-left: 0 4rem 0 0;
  margin-bottom: var(--mb-2);
  animation: pulse 1s;
  font-size: var(--normal-font-size);
  margin-left: -10px;
  padding: 20px;
  border-radius: 30px;
  position: relative;
}

// canvas {
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 1;
//   background: #e8e2e2;
//   opacity: 0.4;
//   border-radius: 30px;
//   z-index: 2;
//   mix-blend-mode: screen;
//   cursor: url("../../assets/brush.png") 2 2, auto;
// }

.stage-cube-cont {
  width: 350px;
  height: 100%;
  padding-top: 10%;
  padding-left: 60%;
}

.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 220px);

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid rgb(255, 252, 252);
    background: rgba(252, 252, 252, 0.96);
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 rgb(238, 224, 255);
  }

  .face1 {
    transform: translateZ(100px);
  }
  .face2 {
    transform: rotateY(90deg) translateZ(100px);
  }
  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
  }
  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
  }
  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
  }
  .face6 {
    transform: rotateX(-90deg) translateZ(100px);
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 700px) {
  body {
    margin: 0 0 var(--header-height) 0;
  }

  .about__container {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
  }

  .stage-cube-cont {
    width: 100%;
    height: 300px;
    padding-top: 10%;
    padding-left: 15rem;
    overflow: visible;
  }

  .about__data {
    text-align: center;
  }

  .about__description {
    padding: 0 4rem;
    margin-bottom: 2rem;
  }

  .button-about {
    margin-left: 0;
  }
}

/* For medium devices */
@media screen and (max-width: 500px) {
  .about__description {
    padding: 0.5rem;
  }
}
