.home__container {
  min-height: 80vh;
}

.home__content {
  grid-template-columns: 116px repeat(2, 1fr);
  /* padding-top: 5.5rem; */
  column-gap: 2rem;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 0.7rem;
}

.home__social-icon {
  font-size: 1.5rem;
  color: var(--text-color);
}

.home__social-icon:hover {
  color: var(--special-color);
}

/* twitter */
.twitter-icon {
  fill: var(--special-color);
  width: 25px;
  /* margin-left: 15px; */
}

.twitter:hover #body {
  fill: var(--special-color);
}

.twitter:hover #wing1 {
  fill: var(--special-color);
  transform-origin: center;
  animation: flap 0.4s ease-out infinite alternate;
}

.twitter:hover #wing2 {
  fill: var(--special-color);
  transform-origin: center;
  animation: flap 0.4s ease-in infinite alternate;
}

@keyframes flap {
  50% {
    transform: scaleX(-1) rotate(-45deg) translate(-40px, -40px);
  }
}
/* twitter ends */

/* github */

.github-icon {
  width: 28px;
  margin-left: -3px;
  fill: var(--text-color);
  margin-bottom: -5px;
}

.github:hover #body {
  fill: var(--special-color);
}
.github:hover #arm {
  transform-origin: bottom right;
  animation: moveArm 1s infinite alternate;
  fill: var(--special-color);
}

@keyframes moveArm {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

/* instagram */

.instagram-icon {
  width: 43px !important;
  margin-bottom: -10px;
  margin-left: -10px;
}
.st0 {
  fill: none;
  /* stroke: #ef4649; */
  stroke: var(--text-color);
  stroke-width: 7;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.instagram:hover #littleCircle {
  stroke-dasharray: 150;
  stroke: var(--special-color);
}

.instagram #shutter {
  display: none;
}
.instagram:hover #shutter {
  display: block;
  animation: shutter 1s linear infinite;
  fill: var(--special-color);
}

.instagram:hover #lens {
  /* fill: #ef4649; */
  fill: var(--special-color);
  animation: lens 1.5s linear infinite;
}

@keyframes shutter {
  0%,
  50% {
    stroke-dashoffset: 150;
  }
  75% {
    fill: #ef4649;
    stroke-dashoffset: 0;
    stroke-width: 11;
  }
}

@keyframes lens {
  0%,
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* linkedin */

.linkedin-icon {
  /* margin-left: 15px; */
  width: 23px;
  margin-bottom: -7px;
}
.linkedin:hover .linkedin-icon > rect {
  animation: rect-up 0.3s linear infinite;
  fill: var(--special-color);
}

.linkedin:hover .linkedin-icon > path {
  fill: var(--special-color);
}

@keyframes rect-up {
  50% {
    y: 10px;
    height: 11px;
  }
  0% {
    y: 13px;
    height: 8px;
  }
}

.linkedin:hover .linkedin-icon > circle {
  animation: bounce 0.4s linear infinite;
}

@keyframes bounce {
  75% {
    cy: 8px;
  }
  50% {
    cy: 4px;
  }
  0% {
    cy: 4px;
  }
}

.home__title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-0-25);
}

.logoY {
  width: 32px;
  /* opacity: 0; */
  height: auto;
  animation: rotateIn 1s linear both;
  animation-delay: 1.4s;
}

.home__subtitle {
  position: relative;
  font-size: var(--h3-font-size);
  padding-left: 5rem;
  font-weight: var(--font-normal);
  margin-bottom: var(--mb-1);
}

.home__subtitle::before {
  content: "";
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: var(--text-color);
  left: 0;
  top: 1rem;
}

.home__description {
  max-width: 400px;
  margin-bottom: var(--mb-3);
}

.home__img {
  background: url(../../assets/profile.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 1px 1px 1px 5px rgb(255 255 255 / 30%);
  order: 1;
  justify-self: center;
  width: 250px;
  height: 250px;
  border-radius: 100%;
  /* animation: profile__animate 8s ease-in-out infinite 1s; */
}

.button {
  margin-top: 10px;
  background-color: var(--container-color);
  border-radius: 0;
  color: var(--title-color);
  border: 1px solid var(--text-color);
  padding: 10px;
}

.button:hover {
  background-color: var(--light-special-color);
  border-bottom: 3px solid var(--special-color);
  border-right: 3px solid var(--special-color);
}

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .home__content {
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1.25rem;
  }

  .home__title {
    font-size: 2.4rem;
  }

  .home__subtitle {
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
  }

  .home__subtitle::before {
    width: 42px;
    top: 0.8rem;
  }

  .home__description {
    max-width: initial;
    margin-bottom: var(--mb-2-5);
  }

  .home__img {
    width: 250px;
    height: 250px;
  }
}

@media screen and (max-width: 896px) {
  .home__title {
    font-size: 1.8rem;
    margin-left: -30px;
  }

  .home__subtitle {
    font-size: 0.9rem;
    margin-left: -30px;
  }

  .home__button {
    margin-left: -30px;
  }
}

/* For medium devices */
@media screen and (max-width: 700px) {
  .home__title {
    font-size: 1.7rem;
    margin-left: -50px;
  }

  .home__subtitle {
    margin-left: -50px;
    font-size: 0.9rem;
  }

  .home__button {
    margin-left: -50px;
  }

  .home__img {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 629px) {
  .home__content {
    grid-template-columns: 0.5fr 3fr;
    padding-top: 3.5rem;
  }

  .home__img {
    order: initial;
    justify-self: initial;
  }

  .home__data {
    grid-column: 1/3;
  }

  .home__img {
    width: 250px;
    height: 250px;
  }

  .home__title {
    font-size: 1.9rem;
    margin-bottom: var(--mb-0-25);
    margin-left: 6rem;
  }

  .home__subtitle {
    margin-left: 6rem;
    font-size: 1rem;
  }

  .home__button {
    margin-left: 6rem;
  }
}

/* For small devices */
@media screen and (max-width: 426px) {
  .home__img {
    width: 180px;
    height: 180px;
  }

  .home__title {
    font-size: 1.35rem;
    margin-bottom: var(--mb-0-25);
    margin-left: 4rem;
  }

  .home__subtitle {
    margin-left: 4rem;
    font-size: 0.7rem;
  }

  .home__button {
    margin-left: 4rem;
  }
}

@media screen and (max-width: 319px) {
  .home__title {
    font-size: 1.2rem;
    margin-bottom: var(--mb-0-25);
    margin-left: 1rem;
  }

  .home__subtitle {
    margin-left: 1rem;
    font-size: 0.75rem;
  }
}
