.art {
  display: grid;
  height: 100%;
  place-items: center;
}

.art__container {
  position: relative;
  background: white;
  height: 455px;
  width: 694.5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.art__container .images {
  height: 100%;
  width: 100%;
  display: flex;
}

.art__container .images .img-1 {
  height: 100%;
  width: 100%;
  background: url("../../assets/color_art.jpg") no-repeat;
  background-size: cover;
}

.art__container .images .img-2 {
  position: absolute;
  height: 100%;
  width: 50%;
  background: url("../../assets/bw_art.png") no-repeat;
  background-size: cover;
}

.art__container .slider {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

.art__container .slider input {
  width: 100%;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
}

.slider input::-webkit-slider-thumb {
  height: 455px;
  width: 3px;
  background: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: col-resize;
}

.slider .drag-line {
  width: 2px;
  height: 455px;
  position: absolute;
  left: 49.85%;
  pointer-events: none;
}

.slider .drag-line::before,
.slider .drag-line::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 425px;
  background: white;
}

.slider .drag-line span {
  height: 30px;
  width: 30px;
  border: 2px solid white;
  position: absolute;
  bottom: -15px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.slider .drag-line span::before,
.slider .drag-line span::after {
  position: absolute;
  content: "";
  top: 50%;
  border: 5px solid transparent;
  border-bottom-width: 0px;
  border-right-width: 0px;
  transform: translate(-50%, -50%) rotate(45deg);
}

.slider .drag-line span::before {
  left: 40%;
  border-left-color: #fff;
}

.slider .drag-line span::after {
  left: 60%;
  border-top-color: #fff;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 700px) {
  body {
    margin: 0 0 var(--header-height) 0;
  }

  .art__container {
    height: 303.33px;
    width: 463px;
  }

  .slider input::-webkit-slider-thumb {
    height: 303.33px;
    width: 3px;
  }

  .slider .drag-line {
    width: 2px;
    height: 303.33px;
  }

  .slider .drag-line::before,
  .slider .drag-line::after {
    height: 278px;
  }

  .slider .drag-line span {
    height: 25px;
    width: 25px;
    bottom: -12px;
  }
}

/* For medium devices */
@media screen and (max-width: 500px) {
  .art__container {
    height: 227.5px;
    width: 347.25px;
  }

  .slider input::-webkit-slider-thumb {
    height: 227.5px;
    width: 3px;
  }

  .slider .drag-line {
    width: 1px;
    height: 227.5px;
  }

  .slider .drag-line::before,
  .slider .drag-line::after {
    height: 214px;
  }

  .slider .drag-line span {
    height: 15px;
    width: 15px;
    bottom: -8px;
  }
}
