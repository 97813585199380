.project__container {
  padding: 0 41px;
}

.project__card {
  border-radius: 5px;
  /* min-height: 630px; */
  align-self: center;
  margin-top: -120px;
  padding-top: 100px;
}

.project__name {
  font-family: var(--body-font);
  font-weight: var(--font-medium);
  margin: 1rem 0;
}

.project__img__subtitle {
  margin-top: 10px;
  width: 110px;
  opacity: 0.4;
  margin-bottom: -55px;
}

.project-image__section {
  justify-content: center;
  display: flex;
}

.project__img {
  width: 65%;
}

.stack {
  margin-bottom: 10px;
  margin-right: 8px;
  background-color: var(--light-special-color);
  color: var(--title-color-dark);
  padding: 5px;
  border-radius: 3px;
  box-shadow: 1px 1px 1px var(--special-color);
  cursor: grab;
}

.project__description {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1.1rem;
  color: var(--title-color);
}

/* Swiper Class */
.swiper-button-next {
  margin-right: -5px !important;
  margin-top: -90px !important;
}

.swiper-button-prev {
  margin-left: -5px !important;
  margin-top: -90px !important;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(236, 234, 248, 0.5);
  padding: 25px 25px;
  border-radius: 20%;
  /* border: 2px solid var(--special-color); */
  color: rgb(78, 70, 151) !important;
}
.swiper-pagination-bullet {
  width: 25px;
  height: 25px;
  background-color: rgb(169, 155, 213) !important;
}
.swiper-pagination {
  top: 95%;
}
