.journey__container {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}

.img__subtitle {
  margin-top: -5px;
  width: 100px;
  opacity: 0.5;
  margin-bottom: -60px;
}

.journey__tabs {
  display: flex;
  justify-content: center;
  margin-bottom: var(--mb-2);
}

.journey__button {
  font-weight: var(--font-medium);
  color: var(--title-color);
  margin: 0 0.5rem;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;

  transition: 0.3s;
  position: relative;

  text-align: center;
  width: 150px;
  /* background-color: var(--container-color);
    box-shadow: 1px 1px 1px 1px rgb(235, 234, 242);

    border-radius: 5px;
    padding: 6px; */
}

.journey__button::after {
  content: "";
  display: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--special-color);

  transform: scaleX(0);
  transform-origin: center;
  transition: transform 250ms ease-in;
}

.journey__icon {
  font-size: 1.5rem;
  margin-right: var(--mb-0-25);
}

.journey__active {
  color: var(--special-color);
}

.journey__button:hover {
  color: var(--title-color-dark);
}

.journey__button:hover::after {
  transform: scaleX(1);
}

/* Journey sections */

.journey__sections {
  display: flex;
  grid-template-columns: 1 fr;
  justify-content: center;
}

.journey__content {
  display: none;
  width: 100%;
}

.journey__content-active {
  display: block;
}

.journey__data {
  background-color: var(--container-color);
  display: flex;
  padding: 15px;
  margin: 10px;
  box-shadow: 1px 1px 5px 1px rgb(233, 231, 240);
  border-radius: 5px;
  align-items: center;
  position: relative;
}

.journey__img {
  width: 45px;
  height: 45px;
  border-radius: 5px;
}

.journey__text-box {
  margin-left: 25px;
  margin-right: 65px;
}

.journey__title {
  font-family: var(--body-font);
}

.journey__company {
  margin: 2px 0;
  font-weight: var(--font-normal);
}

.journey__date {
  font-size: var(--smaller-font-size);
}

.expand__icon {
  position: absolute;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
  transition: 0.4s;
}

.journey__description {
  background-color: var(--container-color);
  padding: 15px 25px 15px 40px;
  margin: 10px;
  margin-top: -16px;
  box-shadow: 1px 1px 6px 1px rgb(233, 231, 240);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: none;
  align-items: center;
  line-height: 1.43rem;
}

.description__close {
  display: none;
}

.description__open {
  display: block;
}

.description__open .expand__icon {
  transform: rotate(-180deg);
}

.ul,
.ul-small-gap {
  list-style-type: disc;
}

.ul li {
  margin-top: 10px;
}

.ul-small-gap li {
  margin-top: 0px;
}

.education__description {
  margin-top: 10px;
  font-size: 1rem;
}

@media screen and (max-width: 480px) {
  .journey__icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .journey__heading {
    display: none;
  }
}
