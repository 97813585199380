.blog__container {
  width: 750px;
}

.blog__card {
  margin-top: 20px;
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2rem;
  margin-bottom: 3rem;
  box-shadow: 5px 5px 5px var(--light-special-color);
}

.image__section {
  position: relative;
}

.blog__img {
  box-shadow: 1px 1px grey;
  margin-bottom: var(--mb-1);
  display: block;
  width: 100%;
}

.image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  font-size: 1.8rem;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.image__overlay--blur {
  backdrop-filter: blur(3px);
}

.text {
  color: white;
}

.image__overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.image__overlay:hover {
  opacity: 1;
  box-shadow: 2px 2px var(--special-color);
  margin-bottom: var(--mb-1);
}

.image__overlay:hover > * {
  transform: translateY(0);
}

.blog__name {
  font-family: var(--body-font);
  font-weight: var(--font-medium);
  margin-bottom: 0.9rem;
  position: relative;
  height: 75px;
  vertical-align: middle;
  display: table-cell;
}

.blog__name:hover {
  color: var(--title-color-dark);
}

.blog__img__subtitle {
  margin-top: 10px;
  width: 110px;
  opacity: 0.5;
  margin-bottom: -50px;
}

.blog__description {
  overflow: hidden;
  height: 70px;
}

/* Breakpoints */
@media screen and (max-width: 992px) {
  .blog__container {
    width: initial;
  }

  .blog__card {
    padding: 1.25rem 1.5rem;
  }
}
